
interface Fracionados{
    id? : number
    codigoProduto : string 
    descricao : string
    razao : any
    tipoProduto : string 
    idGrupo : number
}

import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import useAlert from "@/composables/Alert";
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue"
import Api from "@/services/Api";
import { useLoaderStore } from "@/store/LoaderStore"
import ButtonVue from "@/components/Button.vue"
import ModalCreateOrUpdateFracionados from "./components/ModalCreateOrUpdateFracionados.vue"

export default defineComponent({
  name: "ProdutosFracionados",
  components: {Title, ModalCreateOrUpdateFracionados, Button},
  emits: [],
  setup() {
    const { showTimeAlert } = useAlert();
    const router = useRouter();
    const auxModal: Ref<any> = ref('');
    const loaderStore = useLoaderStore();
    const fracionadosArray: Ref<Fracionados[]> = ref([])
    const fracionadosSelected: Ref<Fracionados | any> = ref(null)
    const inputFind: Ref<string> = ref('')
    const totalProdutosDatabase: Ref<number> = ref(0)
    const storeGroups: Ref<any[]> = ref([]);
    const idGrupo: Ref<number | null> = ref(null)

    async function getStoreGroups() {
      const { data } = await Api.get("getAllStoreGroup");
      storeGroups.value = data.grupos;
    }

    async function getAllFracionados(pageParam: number, limit: number, input: string, idLoja: number){
        try {
            inputFind.value ? loaderStore.close() : loaderStore.open()
            const {data} = await Api.get("produtosFracionados", { page: pageParam, limit, input: input, idGrupo: idGrupo.value });
            fracionadosArray.value = data.original.produtos
            totalProdutosDatabase.value = data.original.totalProdutos
        } catch (error) {
            console.log(error);
        } finally {
            loaderStore.close()
        }
    }

    async function fracionadoById(id:number) {
        try {
            const {data} = await Api.get('fracionadoById', {id: id})
            fracionadosSelected.value = data
        } catch (error) {
            console.log(error);
        }
    }

    function newProduto(){
      if(fracionadosSelected.value){
        resetObject(fracionadosSelected.value)
      } else {
        fracionadosSelected.value = {
          id: "",
          codigoProduto: "",
          descricao: "",
          razao: "",
          tipoProduto: "",
          idGrupo: ""
        }
      }
      openModal('ModalCreateOrUpdateFracionados')
    }

    function usePagination() {
      const state = ref({ currentPage: 1, itemsPerPage: 10, itemsOnPage: 0,});
      const pageCount = computed(() => Math.ceil(totalProdutosDatabase.value / state.value.itemsPerPage));
      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return fracionadosArray.value && fracionadosArray.value.slice(startIndex, endIndex);
      });
      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {
        state,
        setCurrentPage,
        pageCount,
        itemsOnPage,
      };
    }

    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    watch(
      () => state.value.currentPage,
      () => getAllFracionados(state.value.currentPage, state.value.itemsPerPage, inputFind.value, idGrupo.value as number)
    )

    watch(
      () => idGrupo.value,
      () => {getAllFracionados(state.value.currentPage, state.value.itemsPerPage, inputFind.value, idGrupo.value as number)}
    )

    watch(
      () => inputFind.value,
      () => {
        if(inputFind.value.length > 2 || !inputFind.value) {
            getAllFracionados(state.value.currentPage, state.value.itemsPerPage, inputFind.value, idGrupo.value as any)
        }
      }
    )

    async function resetObject(obj) {
      for (const prop in obj) {
        obj[prop] = typeof obj[prop] === "object" ? {} : "";
      }
    }

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = new Modal(auxElement);
      auxModal.value.show();
    };

    function closeModal() {
      getAllFracionados(state.value.currentPage, state.value.itemsPerPage, inputFind.value, idGrupo.value as number)
      auxModal.value.hide()
    }

    onMounted(() => {getAllFracionados(state.value.currentPage, state.value.itemsPerPage, inputFind.value,idGrupo.value as number), getStoreGroups()})

    return {
      router,
      fracionadosArray,
      fracionadosSelected,
      idGrupo,
      totalProdutosDatabase,
      state,
      setCurrentPage,
      pageCount,
      itemsOnPage,
      inputFind,
      fracionadoById,
      openModal,
      newProduto,
      closeModal,
      storeGroups
    };
  },
});
