
interface Fracionados{
    id? : number
    codigoProduto : string 
    descricao : string
    razao : any
    tipoProduto : string 
    idGrupo : number
}

import { defineComponent, Ref, ref, onUpdated, onMounted, onBeforeMount, onUnmounted, watch } from "vue";
import Api from "@/services/Api";
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore"
import Button from "@/components/Button.vue"

export default defineComponent({
  name: "ModalCreateOrUpdateFracionados",
  components: {
    Button,
  },
  emits: ["closeModal"],
  props: { fracionadosSelected: Object, storeGroups: Array },

  setup(props, { emit }) {
    let auxModal;
    const { showTimeAlert } = useAlert();
    const spanWarning = ref(false);
    const loaderStore = useLoaderStore();
    const dialogVisible: Ref<boolean> = ref(false);
    const fracionadosSelected: Ref<Fracionados | any> = ref(null)
    const tipoProdutoSelect: Ref<Object[]> = ref([
      {tipo:'oleo', nome:'Oleo motor'},
      {tipo:'fluidoDeFreio', nome:'Fluido de freio'},
      {tipo:'liquidoArrefecimento', nome:'Liquido de arrefecimento'},
      {tipo:'oleoCambioManual', nome:'Oleo cambio manual'},
      {tipo:'oleoCambioAut', nome:'Oleo cambio automatico'},
    ])

    async function createOrUpdate() {
      try {
        if(verificaValor()) return spanWarning.value = true
        loaderStore.open()
        const res = await Api.post('createOrUpdate', {...fracionadosSelected.value})
        if (res.error) {
            showTimeAlert('Confira os campos e tente novamente', 'error');
        } else {
            showTimeAlert(fracionadosSelected.value.id ? 'Produto atualizado' : 'Produto adicionado', 'success');
            emit('closeModal');
        }
      } catch (error) {
        console.log(error);
      } finally {
        loaderStore.close()
      }
    }

    async function deleteFracionado(id: number) {
      try {
        loaderStore.open()
        const res = await Api.delete('deleteFracionado', {id})
        showTimeAlert('Produto deletado', 'success'); 
        emit('closeModal')
        dialogVisible.value = false
      } catch (error) {
        console.log(error);
      } finally {
        loaderStore.close()
      }
    }

    function verificaValor(){
      if(!fracionadosSelected.value.codigoProduto || !fracionadosSelected.value.descricao || !fracionadosSelected.value.razao || !fracionadosSelected.value.tipoProduto || !fracionadosSelected.value.idGrupo ){
        return true
      } 
      return spanWarning.value = false
    }

    watch(() => props.fracionadosSelected, () => fracionadosSelected.value = props.fracionadosSelected)

    return {
      spanWarning,
      fracionadosSelected,
      tipoProdutoSelect,
      dialogVisible,
      createOrUpdate,
      deleteFracionado
    };
  },
});
